import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import { ChartTypeEnum } from '@/models/kline';
import { useModel } from '@@/exports';
import { history } from '@umijs/max';
import { useMount, useUnmount } from 'ahooks';
import * as echarts from 'echarts';
import { useTheme } from 'next-themes';
import { useEffect, useMemo, useRef } from 'react';

type IProps = {
  data: Array<{
    name: string;
    value: number;
    children?: Array<{ name: string; value: number; pe: number }>;
  }>;
  canvasWidth: string;
  canvasHeight: string;
  setSelectCoin?: any;
  sort: any;
};

export default (props: IProps) => {
  const { data = [], canvasWidth, canvasHeight, setSelectCoin, sort } = props;
  const trend = useRef<any>();
  const { setCoinType } = useModel('kline');
  const { setLocalContract, setLocalFastContract } = useModel('contract');
  const { isMobile } = useModel('system');
  const chart = useRef<any>();
  // 处理窗口大小调整事件
  const handleResize = () => {
    chart.current.resize();
  };

  useMount(() => {
    // 初始化图表实例
    chart.current = echarts.init(trend.current);

    // 添加点击事件监听
    chart.current.on('click', function (params: any) {
      if (params.data) {
        setCoinType(ChartTypeEnum.CONTRACT);
        setLocalContract(params.data.coin);
        if (!isMobile) {
          history.push(PageEnum.HOME + '?type=' + WalletTypeEnum.CONTRACT);
          return;
        }
        history.replace(PageEnum.CONTRACT);
      }
    });
    window.addEventListener('resize', handleResize);
  });

  useUnmount(() => {
    chart.current.dispose();
    window.removeEventListener('resize', handleResize);
  });

  // 将这四个值的数据项的值都设置为最小值
  // 将这四个值的数据项的值都设置为最小值
  const equalizedData = useMemo(() => {
    if (!data || data.length === 0) return [];

    const minPercentage = 10; // 最小占比百分比
    const maxPercentage = 30; // 最大占比百分比

    // 获取数据的最小值和最大值
    const values = data.map((item) => item.value);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    // 如果所有值相等，统一为 minPercentage
    if (minValue === maxValue) {
      return data.map((item, index) => ({
        ...item,
        value: minPercentage,
        index,
      }));
    }
    // 平移量，使最小值变为 minPercentage
    const offset = Math.abs(minPercentage - minValue);

    // 根据数据的最大最小值分别计算缩放因子
    const positiveScaleFactor =
      maxValue > 0
        ? (maxPercentage - minPercentage) / (maxValue - minValue)
        : 1; // 如果没有正值，不需要缩放

    const negativeScaleFactor =
      minValue < 0
        ? (minPercentage - maxPercentage) / (minValue + offset - maxPercentage)
        : 1; // 如果没有负值，不需要缩放

    // 调整所有值
    return data.map((item, index) => {
      let adjustedValue;

      if (item.value < 0) {
        // 负值处理
        // 负数计算：先去绝对值，再按比例调整
        adjustedValue =
          (Math.abs(item.value) - Math.abs(minValue)) * negativeScaleFactor +
          minPercentage;

        // 如果值过小，使用最小值替代
        if (adjustedValue < minPercentage) {
          adjustedValue = minPercentage;
        }
      } else {
        // 正值处理
        adjustedValue =
          (item.value - minValue) * positiveScaleFactor + minPercentage;
      }

      return { ...item, value: adjustedValue, index };
    });
  }, [data, sort]);

  const trendColor = [
    '#cf3c4e', //< -20%
    '#f4455c', //< -20% - -10%
    '#f66a7b', //< -10% - 0%
    '#767f8e', //  0%
    '#3dd399', //  10%
    '#0fc980', //  10% - 20%
    '#0eaa6e', //  > 20%
  ];

  // 根据值返回对应的颜色
  function getColorByValue(value: number) {
    if (value < -20) {
      return trendColor[0];
    } else if (value < -10) {
      return trendColor[1];
    } else if (value < 0) {
      return trendColor[2];
    } else if (value === 0) {
      return trendColor[3];
    } else if (value > 20) {
      return trendColor[6];
    } else if (value > 10) {
      return trendColor[5];
    } else if (value > 0) {
      return trendColor[4];
    }
  }

  const { theme } = useTheme();

  useEffect(() => {
    if (trend.current) {
      if (chart.current) {
        const pieces = equalizedData.map((item) => {
          const { value, pe }: any = item;

          return {
            min: value,
            max: value,
            color: getColorByValue(pe), // 自定义函数，根据值设置颜色
          };
        });

        chart.current.setOption({
          backgroundColor: '#000',
          visualMap: {
            show: false,
            type: 'piecewise',
            pieces: pieces,
            calculable: true,
          },
          series: [
            {
              type: 'treemap',
              data: equalizedData,
              label: {
                show: true,
                formatter: '{b}',
              },
              itemStyle: {
                borderColor: theme === ThemeEnum.dark ? '#fff' : '#000',
                gapWidth: 1,
              },
              roam: false,
              breadcrumb: {
                show: false,
              },
              width: '100%',
              height: '100%',
            },
          ],
          grid: {
            containLabel: true,
          },
        });
      }
    }
  }, [data?.[0]?.name, sort.value, data]);

  return (
    <div
      id="trend"
      ref={trend}
      style={{ width: canvasWidth, height: canvasHeight }}
    />
  );
};
