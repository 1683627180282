import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default () => {
  const { appInfo } = useModel('appInfo');

  // 这个配置是否 开启逐仓
  const { EARNEST_MODE_CONTROL } = appInfo || {};

  /*---------------------筛选状态--------------------------------*/
  //获取国际化函数
  const { t } = useTranslation();

  // 方向筛选
  /**
   * 二层网络Layer2、人工智能AI、元宇宙Metaverse、链游GameFi
   */

  //交易类型 限价/市价
  const tradeTypeOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('二层网络Layer2'),
      value: '1',
    },
    {
      text: t('人工智能AI'),
      value: '2',
    },
    {
      text: t('元宇宙Metaverse'),
      value: '3',
    },
    {
      text: t('链游GameFi'),
      value: '4',
    },
  ];

  //订单卡片根据后端状态映射中文
  const getStateMap = (status: number) => {
    let value = '';
    switch (status) {
      case -2:
        value = t('已强平');
        break;
      case -1:
        value = t('已撤销');
        break;
      case 0:
        value = t('已结算');
        break;
      case 1:
        value = t('委托中');
        break;
      case 2:
        value = t('持仓中');
        break;
    }
    return value;
  };

  return {
    tradeTypeOption,
    getStateMap,
  };
};
