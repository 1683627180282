import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseSift from '@/components/base/baseSift';
import PageEnum from '@/enums/pageEnum';
import { getHeatmapListApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Trend from './components/Trend';
import useConstants from './useConstants';

/**
 * 热力图
 * 界面
 */

export default ({setLoadingDisplay}:{ setLoadingDisplay?:(val:boolean)=>void }) => {
  const { t } = useTranslation();
  const { getSocketRowByName, sendMsg } = useModel('socket');
  const { exchangeSetConvert } = useModel('exchange');

  const {
    data: list = [] as any,
    run: getlistRequst,
    loading,
  } = useReq(getHeatmapListApi, {
    manual: true,
    defaultValue: [],
    formatResult(res: any) {
      return res?.data?.list ?? [];
    },
    onSuccess(data: any) {
      if (data?.length) {
        sendMsg(
          `{"type":"SUB","data": ${JSON.stringify(
            data?.map((i: { symbol: any }) => i?.symbol),
          )}}`,
        );
      }
    },
  });
  const { tradeTypeOption } = useConstants();
  const [type, setType] = useState(tradeTypeOption[0]);

  const sorArr = [
    {
      text: t('成交量'),
      value: '1',
    },
    {
      text: t('涨跌幅'),
      value: '2',
    },
  ];
  const [sort, setSort] = useState(sorArr[0]);

  const [selectCoin, setSelectCoin] = useState<any>(null);
  useEffect(() => {
    if (location.pathname !== PageEnum.MARKET) return;
    getlistRequst({
      plate: type.value,
      heatmapSort: sort.value,
    });
  }, [type, sort, location]);

  useEffect(() => {
    if (list?.length && !selectCoin) {
      setSelectCoin(list?.[0]);
    }
  }, [list]);

  useEffect(() => {
    if (list?.length) {
      setSelectCoin(list?.[0]);
    } else {
      setSelectCoin(null);
    }
  }, [type, list]);

  // 修改加载
  useEffect(()=>{
    if(setLoadingDisplay){
      setLoadingDisplay?.(loading)
    }
  },[loading])
  /**
   * plate
   */

  /**订单过滤条件 */
  const filterList = [
    // {
    //   options: tradeTypeOption,
    //   onChange: setType,
    //   current: type,
    //   title: t('全部'),
    // },
    {
      options: sorArr,
      onChange: setSort,
      current: sort,
      title: t('成交量'),
    },
  ];
  const marketList = useModel('socket');

  // 是否全部能拿到值
  const isAllAccepted = useMemo(() => {
    if (!list?.length) return false;
    return list?.every?.((item) => {
      return getSocketRowByName(item.symbol)?.p !== '';
    });
  }, [marketList, list]);

  const renderList = useMemo(() => {
    if (list?.length === 0 || !isAllAccepted) return [];
    return list?.map((item: any) => {
      const soketData = getSocketRowByName(item.symbol);
      return {
        name: `${item?.alias} \n  ${exchangeSetConvert({
          price:soketData?.a,
          isJoin:true
        })}  \n ${RenderUtil.FormatAmount(soketData.pe, 2)}% `,
        value: sort.value === '1' ? parseFloat(item?.amount) : soketData?.pe,
        pe: soketData?.pe,
        coin: item,
      };
    });
  }, [list, sort, isAllAccepted]);

  const [trendKey, setTrendKey] = useState('1');
  useEffect(() => {
    if (window.location.pathname !== PageEnum.MARKET) return;
    setTrendKey((prevState) => prevState + 1);
  }, [window.location.pathname, sort.value]);

  // 热力图颜色区间
  const trendColor = [
    {
      color: '#cf3c4e',
      title: '< -20%',
    },
    {
      color: '#f4455c',
      title: '-20% < -10%',
    },
    {
      color: '#f66a7b',
      title: '-10% < 0%',
    },
    {
      color: '#767f8e',
      title: '0%',
    },
    {
      color: '#3dd399',
      title: '0% < 10%',
    },
    {
      color: '#0fc980',
      title: '10% < 20%',
    },
    {
      color: '#0eaa6e',
      title: '> 20%',
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex px-0 pt-3 pb-3">
          {/* 订单过滤条件  */}
          {filterList.map((item: any) => {
            return (
              <div key={item?.title}>
                <BaseSift
                  key={item?.title}
                  options={item?.options}
                  onChange={item.onChange}
                  current={item.current}
                  title={item.title}
                />
              </div>
            );
          })}
        </div>
        <div className="flex items-center gap-1">
          {trendColor.map((item) => {
            return (
              <Tooltip
                key={item?.color}
                placement="top"
                title={<span className="text-[#efefef]">{item?.title}</span>}
              >
                <div
                  className="w-3 h-3 sm:cursor-pointer"
                  style={{ background: item?.color }}
                ></div>
              </Tooltip>
            );
          })}
        </div>
      </div>

      <BaseLoadingDisplay
        loading={loading || !isAllAccepted}
        list={loading || !isAllAccepted ? [] : list}
        isShowDisplayLoading={false}
      >
        <Trend
          key={trendKey}
          setSelectCoin={setSelectCoin}
          sort={sort}
          data={renderList}
          canvasWidth={'w-full'}
          canvasHeight={'360px'}
        />
      </BaseLoadingDisplay>
    </div>
  );
};
